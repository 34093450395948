import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getTutorial(context, params) {
      return new Promise((resolve, reject) => {
        axios.get(`tutorial/${params.slugCategoria}/${params.slug}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getCategorias() {
      return new Promise((resolve, reject) => {
        axios.get('tutorial/categoria')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
